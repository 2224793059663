import * as React from "react";

import { Helmet } from "react-helmet";

import { Button } from "react-bootstrap";

import PageLayout from "../components/PageLayout";
import HeroBanner from "../components/HeroBanner";
import PlainModule from "../components/PlainModule";

import CavernGodRayImage from "../images/iStock-1354407614.jpg";

import ParasiticCapacitancePdf from "../assets/MIT-ParasiticCapacitance.pdf";
import Ldc1101Pdf from "../assets/Single-Coil_Magnetic_Induction_Tomography_Using_the_LDC-1101_Chip.pdf";
import MedicalImagingPdf from "../assets/MIT_MedicalImaging.pdf";
import InternalMitPdf from "../assets/InternalMIT_SingleCoil.pdf";
import CoilGeometryEffects from "../assets/Feldkamp_2017_Phys_Med_Biol_62_7097.pdf";
import OpticallyTrackedPdf from "../assets/OpticallyTrackedMIT.pdf";

const PapersPage = () => {
  return (
    <PageLayout>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}>
        <title>Tayos Corp - Academic Papers</title>
      </Helmet>

      <HeroBanner backgroundImage={CavernGodRayImage} backgroundShift="47%" />

      <PlainModule variant="left">
        <h1 className="display-4">Light Bedtime Reading</h1>
        <p className="lead text-muted">
          Actively advancing the boundaries of academic knowledge
        </p>
        <p className="leadLeftParagraph">
          Tayos is at the forefront of quantitative electrical conductivity sensors and single-coil MIT, contributing original research 
          to respected, peer-reviewed journals.  Although we're dedicated to making our cutting-edge technology available everywhere, we 
          stand apart from other firms by giving back to the research community.
        </p>
        <Button variant="secondary" href="/about/">More About Tayos</Button>

        <div className="mx-md-5 mx-sm-2 mt-5">
          <p>
            <h2 className="lead h4">2022</h2>
            <div className="ms-4">
              <a href="https://ieeexplore.ieee.org/document/9946998">Parasitic Capacitance Associated With Inductive
Sensors Used in MIT Imaging</a>{" "}
              (IEEE Journal){" "}
              (<a href={ParasiticCapacitancePdf}>Download</a>)
            </div>
          </p>
          <p>
            <h2 className="lead h4">2020</h2>
            <div className="ms-4">
              <a href="https://ieeexplore.ieee.org/abstract/document/9154723">Single-Coil Magnetic Induction Tomography Using the LDC-1101 Chip</a>{" "}
              (IEEE Journal){" "}
              (<a href={Ldc1101Pdf}>Download</a>)
            </div>
          </p>
          <p>
            <h2 className="lead h4">2019</h2>
            <div className="ms-4">
              <a href="https://www.jpier.org/PIER/pier.php?paper=18120408">Internal Magnetic Induction Tomography Using a Single Coil</a>{" "}
              (PIER - Progress In Electromagnetics Research){" "}
              (<a href={InternalMitPdf}>Download</a>)
            </div>
          </p>
          <p>
            <h2 className="lead h4">2017</h2>
            <div className="ms-4">
              <a href="https://www.spiedigitallibrary.org/journals/journal-of-medical-imaging/volume-4/issue-2/023504/Optically-tracked-single-coil-scanning-magnetic-induction-tomography/10.1117/1.JMI.4.2.023504.full">
                Optically tracked, single-coil, scanning magnetic induction tomography
              </a>{" "}
              (SPIE){" "}
              (<a href={OpticallyTrackedPdf}>Download</a>)
              <br />
              <a href="https://iopscience.iop.org/article/10.1088/1361-6560/aa807b/meta">Coil geometry effects on scanning single-coil magnetic induction tomography</a>{" "}
              (Physics in Medicine &amp; Biology){" "}
              (<a href={CoilGeometryEffects}>Download</a>)
            </div>
          </p>
          <p>
            <h2 className="lead h4">2015</h2>
            <div className="ms-4">
              <a href="https://www.spiedigitallibrary.org/journals/journal-of-medical-imaging/volume-2/issue-1/013502/Single-coil-magnetic-induction-tomographic-three-dimensional-imaging/10.1117/1.JMI.2.1.013502.full?SSO=1">
              Single-coil magnetic induction tomographic three-dimensional imaging
              </a>{" "}
              (SPIE){" "}
              (<a href={MedicalImagingPdf}>Download</a>)
            </div>
          </p>
        </div>
      </PlainModule>
    </PageLayout>
  )
}

export default PapersPage
